#playlist-app {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  /*
=================================================
GLOBAL
=================================================
*/
  .btn {
    border-radius: 0 !important;
  }
  .pointer {
    cursor: pointer;
  }
  .semi-transparent {
    opacity: 0.5;
  }
  .bg-salmon {
    background-color: #f37165;
  }
  .btn-primary {
    background-color: #f37165 !important;
    border-color: #fc5c4e !important;
  }
  .btn-accent {
    background-color: #f37165 !important;
    border-color: #fc5c4e !important;
  }

  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:focus {
    background-color: #fc5c4e;
    border-color: #fc5c4e;
  }

  /*
=================================================
TYPOGRAPHY
=================================================
*/

  h1,
  h2,
  h3 {
    font-family: "Abril Fatface", sans-serif;
    font-weight: 400;
  }
  h4 {
    font-weight: 700;
  }
  h5 {
    font-weight: 500;
  }
  .text-shadow-sm {
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.4);
  }
  .text-shadow-md {
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.6);
  }
  /*
=================================================
NAVBAR
=================================================
*/
  .navbar {
    text-transform: uppercase;
  }
  .navbar-brand {
    font-family: "Abril Fatface", cursive;
    font-size: 28px;
    color: #f37165 !important;
  }

  .navbar-brand span {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #f1f1f1;
  }

  .navbar .nav-item {
    margin-right: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  /*
=================================================
HERO
=================================================
*/
  .btn-outline-secondary {
    color: #fff;
    border-color: #fc5c4e;
    text-transform: uppercase;
    background: #f37165;
  }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #fc5c4e;
    border-color: #fc5c4e;
  }
  .wax-logo {
    filter: saturate(0%) opacity(75%);
    max-width: 100px;
  }
  .video-container {
    position: relative;
  }
  #background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  /*
=================================================
HOW TO
=================================================
*/
  .how-to i {
    font-size: 48px;
    color: #fff;
  }
  .how-to h5 {
    color: #fff;
  }
  .how-to a {
    color: #000;
    text-decoration: none;
  }
  .how-to p {
    font-weight: 300;
    color: #000;
  }

  /*
=================================================
BUILDER
=================================================
*/
  .cover-art {
    max-height: 400px;
  }
  label {
    font-weight: 500;
  }
  .message {
    height: 200px;
  }
  ::placeholder {
    font-weight: 100;
  }

  /*
=================================================
BUILDER
=================================================
*/

  /*
=================================================
PLAYLIST
=================================================
*/

  /*
=================================================
FOOTER
=================================================
*/
  .footer {
    background-color: #000000;
  }
  .footer p {
    color: #ffffff;
  }
  .footer h4 {
    font-size: 16px;
    font-weight: 700;
    color: #f8f9fa;
  }
  .footer a {
    text-decoration: none;
    color: #f8f9fa;
    font-weight: 300;
  }
  .mailchimp {
    position: relative;
    top: -3rem;
    padding-bottom: 16px;
  }
  .mailchimp a {
    clear: both;
    display: block;
  }
}
