h4 {
  color: #777777;
  font-weight: 300;
  margin-bottom: 0;
}
p {
  font-size: 14px;
  color: #000000;
}
.immutable-attributes p {
  font-size: 10px;
  color: #777777;
}
.alert-sale {
  font-size: 13px;
  border-radius: 0.25rem;
}
.alert-sale span {
  font-weight: 700;
}
