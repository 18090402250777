#market-app {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  /*
=================================================
GLOBAL
=================================================
*/

  div,
  p,
  ol,
  ul,
  li {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  h1,
  h2,
  h3 {
    font-family: "Abril Fatface", sans-serif;
  }
  h4,
  h5,
  h6 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 18;
    font-weight: 300;
    color: #777777;
  }
  h6 {
    font-size: 12px;
  }
  p {
    color: #777777;
    font-size: 18px;
    font-weight: 300;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .heavy {
    font-weight: 900;
  }
  .btn {
    border-radius: 0;
  }
  .btn-light {
    font-weight: 300;
  }
  .btn-group-xs > .btn,
  .btn-xs {
    padding: 0.5rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
  }
  .content-static {
    max-width: 768px;
  }
  /*
=================================================
NAVBAR
=================================================
*/

  .navbar {
    text-transform: uppercase;
  }
  .navbar .nav-item {
    margin-right: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  .navbar-brand {
    color: #000;
    font-size: 28px;
    font-family: "Abril Fatface", cursive;
  }
  .navbar-brand span {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #777777;
  }
  .wax-account {
    text-transform: lowercase;
    color: #000 !important;
    font-size: 1rem;
    font-weight: 500;
  }
  .dropdown-item {
    color: #777777;
    font-size: 1rem;
  }

  /*
=================================================
FOOTER
=================================================
*/
  .footer {
    background-color: #000000;
  }
  .footer p {
    color: #ffffff;
  }
  .footer h4 {
    font-size: 16px;
    font-weight: 700;
    color: #f8f9fa;
  }
  .footer a {
    text-decoration: none;
    color: #f8f9fa;
    font-weight: 300;
  }
  .mailchimp {
    position: relative;
    top: -3rem;
    padding-bottom: 16px;
  }
  .mailchimp a {
    clear: both;
    display: block;
  }
  /*
=================================================
WALLET
=================================================
*/
  #ual-box h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 2rem;
  }
  /*
=================================================
HOMEPAGE
=================================================
*/
  .hero {
    background-color: #f8f9fa;
  }
  .hero h1 {
    color: #000000;
    font-weight: 300 !important;
  }
  .carousel-inner {
    max-height: 800px;
  }
  .carousel-inner img {
    max-height: 800px;
  }
  .carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    left: 25%;
    right: 25%;
  }
  .center {
    background: #00000088;
  }
  .hero-below h4 {
    font-size: 18px;
  }
  i {
    font-size: 48px;
  }

  /*
=================================================
MARKET
=================================================
*/
  .list-item {
    background: #f8f9fa;
    min-height: 300px;
    cursor: pointer;
    height: 100%;
  }
  .list-item a {
    text-decoration: none;
    cursor: pointer;
  }
  .list-item img,
  .list-item video {
    max-height: 300px;
    cursor: pointer;
  }
  .list-item h4 {
    color: #777777;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
  }
  .list-item p {
    font-size: 14px;
    color: #000000;
  }

  /*
=================================================
SALE PAGE
=================================================
*/
  .product-page h4 {
    color: #777777;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0;
  }
  .product-page p {
    font-size: 14px;
    color: #000000;
  }
  .immutable-attributes p {
    font-size: 10px;
    color: #777777;
  }
  .sale-img {
    max-height: 500px;
  }

  /*
=================================================
BUNDLE
=================================================
*/
  .card {
    border-radius: 0;
  }
  .bundle {
    overflow-x: scroll;
    overflow-y: hidden;
    border-left: 15px solid #f8f9fa;
    border-right: 15px solid #f8f9fa;
  }
  .card-bundle {
    min-width: 350px;
    max-width: 350px;
    max-height: 350px;
  }
  .card-bundle img {
    max-height: 300px;
  }
  .bundle-page h2 span {
    color: #777777;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
  }
  .card-bundle:last-of-type {
    margin-right: 10px;
  }
  .card-bundle:first-of-type {
    margin-left: 0px !important;
  }

  /*
=================================================
ROADMAP
=================================================
*/

  ul.timeline {
    list-style-type: none;
    position: relative;
  }
  ul.timeline:before {
    content: " ";
    background: #777777;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }
  ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
  }
  ul.timeline > li:before {
    content: " ";
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #777777;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
  }
  ul.timeline > .finished:before {
    background: #777777 !important;
  }
  .roadmap h4 {
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    text-transform: uppercase;
  }
  .roadmap p {
    color: #777777;
    font-size: 14px;
  }

  /*
=================================================
CONTACT FORM
=================================================
*/
  .contact-form h2,
  .contact-form p {
    z-index: 1;
    position: relative;
  }
  .contact-form iframe {
    width: 100%;
    height: 950px;
    overflow-y: hidden;
    margin-top: -100px;
    position: relative;
    z-index: 0;
  }
  /*
=================================================
TERMS OF SERVICE
=================================================
*/
  .tos h4 {
    font-size: 18px;
    text-transform: uppercase;
  }
  /*
=================================================
FAQ
=================================================
*/
  .faq h4 {
    font-size: 18px;
    text-transform: uppercase;
  }

  /*
=================================================
RESPONSIVE ADJUSTMENTS
=================================================
*/
  /* Large devices (desktops, greater than 1200px) */
  @media (min-width: 1199.98px) {
  }

  /* Large devices (desktops, less than 1200px) */
  @media (max-width: 1199.98px) {
    .list-item img {
      max-height: 238px;
    }
  }

  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .list-item img {
      max-height: 266px;
    }
  }

  /* Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {
    .list-item img {
      max-height: 350px;
    }
  }

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
  }
}
