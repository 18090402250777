.player {
  position: relative;
  min-height: 400px;
}
.loading {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  padding-top: 150px;
}
.embed {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 400px;
}
.spotify {
  color: #1db954;
}
.spotify:hover {
  color: #179644;
}
.youtube {
  color: #ff0000;
}
.youtube:hover {
  color: #db0000;
}
.text-end i {
  font-size: 16px !important;
}
